import React from "react";
import "./home.scss";
import { GranttChart } from "../../components/grantt-chart/GranttChart";

export default function Home() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Home</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}></div>
      </div>
    </React.Fragment>
  );
}
