import React from "react";
import "./proj-management.scss";
import { GranttChart } from "../../components/grantt-chart/GranttChart";

export default () => (
  <React.Fragment>
    <h2 className={"content-block"}>Project Management</h2>
    <div className={"content-block"}>
      <div className={"dx-card responsive-paddings"}>
        <GranttChart />
      </div>
    </div>
  </React.Fragment>
);
